import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { ControlledNumberInput } from '../../../shared';
import { useTranslation } from 'react-i18next';

export const RegisterSessionCashInputsBills: FC = () => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" spacing={2} width="100%">
            <Stack spacing={2} width="100%">
                <ControlledNumberInput
                    name="amount5Euro"
                    label={t('amountBills', { amount: 5 })}
                    slotProps={{ htmlInput: { readOnly: true } }}
                    decimalScale={0}
                />
                <ControlledNumberInput
                    name="amount10Euro"
                    label={t('amountBills', { amount: 10 })}
                    slotProps={{ htmlInput: { readOnly: true } }}
                    decimalScale={0}
                />
                <ControlledNumberInput
                    name="amount20Euro"
                    label={t('amountBills', { amount: 20 })}
                    slotProps={{ htmlInput: { readOnly: true } }}
                    decimalScale={0}
                />
            </Stack>

            <Stack spacing={2} width="100%">
                <ControlledNumberInput
                    name="amount50Euro"
                    label={t('amountBills', { amount: 50 })}
                    slotProps={{ htmlInput: { readOnly: true } }}
                    decimalScale={0}
                />
                <ControlledNumberInput
                    name="amount100Euro"
                    label={t('amountBills', { amount: 100 })}
                    slotProps={{ htmlInput: { readOnly: true } }}
                    decimalScale={0}
                />
            </Stack>
        </Stack>
    );
};
