import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterSessionCashInputsCoins } from './register-session-cash-inputs-coins.component';
import { RegisterSessionCashInputsBills } from './register-session-cash-inputs-bills.component';

export const RegisterSessionCashInputs: FC = () => {
    const { t } = useTranslation();

    return (
        <Stack sx={{ maxHeight: '60vh', overflow: 'auto', width: '100%', pr: 2 }}>
            <Typography variant="subtitle1" mb={1} mt={3}>
                {t('coins')}
            </Typography>
            <RegisterSessionCashInputsCoins />

            <Typography variant="subtitle1" mb={1} mt={3}>
                {t('bills')}
            </Typography>
            <RegisterSessionCashInputsBills />
        </Stack>
    );
};
