export default {
    pages: {
        login: 'Aanmelden | Carity Shop',
        shop: 'Winkel | Carity Shop',
        stock: 'Stockbeheer | Carity Shop',
        admin: 'Admin | Carity Shop',
        history: 'Historiek | Carity Shop',
    },
    shared: {
        somethingWentWrong: 'Er liep iets mis:',
        tryAgainLater:
            'Probeer later nog eens, indien deze foutmelding zich opnieuw voordoet: contacteer de verantwoordelijke.',
        back: 'Terug',
        save: 'Bewaar',
        cancel: 'Annuleer',
        confirm: 'Bevestig',
        next: 'Volgende',
        previous: 'Vorige',
    },

    all: 'Alle',
    save: 'Bewaar',
    cancel: 'Annuleer',
    delete: 'Verwijder',
    filter: 'Filter',
    confirm: 'Bevestig',
    yes: 'Ja',
    no: 'Nee',
    ok: 'Ok',
    previous: 'Vorige',
    next: 'Volgende',
    goBack: 'Ga terug naar de vorige pagina',
    somethingWentWrong: 'Er ging iets mis',
    requiredField: 'Dit veld is verplicht',
    invalidNumber: 'Dit veld moet een getal bevatten',
    invalidInteger: 'Dit veld mag geen komma getallen bevatten',
    negativeNumber: 'Het getal moet positief zijn',
    minLengthBarcode: 'Een barcode moet bestaan uit minstens {{length}} karakters',
    validateRegisterInputs: 'Je kan de kassa sessie niet starten/bijwerken indien alle velden 0 bevatten.',
    edit: 'Wijzig',
    name: 'Naam',
    code: 'Code',
    createdBy: 'Aangemaakt door',
    createdDate: 'Aangemaakt op',
    unknown: 'Onbekend',
    noRows: 'Geen gegevens gevonden.',

    organisation: 'Organisatie',
    getOrganisationsError: 'Er is iets misgelopen bij het ophalen van de organisaties',
    mustNotContain: `Barcode mag geen '{{value}}' bevatten`,

    testingEnvironment: 'Dit is de testomgeving.',
};
