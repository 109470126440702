import { DialogContent, Stack } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { ICash, useSelectedOrganisation } from '../../../shared';
import { InputNumpad } from '../input-numpad/input-numpad.component';
import { RegisterSessionCashInputs } from '../register-session-cash-inputs/register-session-cash-inputs.component';
import { useSettings } from '../../../admin/hooks';

interface Props {
    selected: keyof ICash;
}

export const RegisterSessionInputs: FC<Props> = ({ selected }) => {
    const form = useFormContext();
    const { organisation } = useSelectedOrganisation();
    const { data: settings } = useSettings(organisation?.id);

    const onNumpadInput = useCallback(
        (input: string) => {
            if (selected) {
                form.setValue(selected, input, {
                    shouldValidate: true,
                });
            }
        },
        [form, selected],
    );

    return (
        <DialogContent>
            <Stack direction="row">
                <RegisterSessionCashInputs />
                <InputNumpad
                    value={form.getValues(selected)?.toString()}
                    onChange={onNumpadInput}
                    disable={{ disableSeparator: true, disableButtons: false }}
                    showNumpad={settings?.showNumpad}
                    sx={{ width: '250px', flexShrink: 0, mx: 2, my: 'auto' }}
                />
            </Stack>
        </DialogContent>
    );
};
