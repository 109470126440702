import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { number, object } from 'yup';

const transformNumber = (value: any | undefined) => {
    if (value || value === 0) {
        return value;
    } else {
        return undefined;
    }
};

export function useRegisterSession(stop = false) {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object()
                .shape({
                    amount100Euro: number().transform(transformNumber).min(0, t('negativeNumber')).required(),
                    amount50Euro: number().transform(transformNumber).min(0, t('negativeNumber')).required(),
                    amount20Euro: number().transform(transformNumber).min(0, t('negativeNumber')).required(),
                    amount10Euro: number().transform(transformNumber).min(0, t('negativeNumber')).required(),
                    amount5Euro: number().transform(transformNumber).min(0, t('negativeNumber')).required(),
                    amount2Euro: number().transform(transformNumber).min(0, t('negativeNumber')).required(),
                    amount1Euro: number().transform(transformNumber).min(0, t('negativeNumber')).required(),
                    amount50Cents: number().transform(transformNumber).min(0, t('negativeNumber')).required(),
                    amount20Cents: number().transform(transformNumber).min(0, t('negativeNumber')).required(),
                    amount10Cents: number().transform(transformNumber).min(0, t('negativeNumber')).required(),
                    amount5Cents: number().transform(transformNumber).min(0, t('negativeNumber')).required(),
                    amount2Cents: number().transform(transformNumber).min(0, t('negativeNumber')).required(),
                    amount1Cents: number().transform(transformNumber).min(0, t('negativeNumber')).required(),
                })
                .test('validateRegisterInputs', t('validateRegisterInputs'), (value) => {
                    if (stop) return true;
                    return !Object.values(value).every((item) => item === 0);
                }),
        [t, stop],
    );
}
