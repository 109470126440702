import { ChevronLeft, Sell } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, Divider, Stack, Typography } from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDateString, formatPrice, FormGrid, IParams, LabelValue, mapAmountFromApi } from '../../../shared';
import { PurchaseItemsTable, SavePurchaseItem } from '../../components';
import { CompletePurchaseActions } from '../../components/complete-purchase-actions/complete-purchase-actions.component';
import { useCompletePurchase, usePurchase } from '../../hooks';
import { IPurchaseItem } from '../../models';

export const PurchaseItemsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;
    const [showConfirm, setShowConfirm] = useState<boolean>(false);

    const { data: purchase } = usePurchase(id);
    const { mutateAsync: completePurchase, isPending } = useCompletePurchase();
    const [editPurchaseItem, setEditPurchaseItem] = useState<IPurchaseItem | undefined>(undefined);
    const [open, setOpen] = useState(false);

    const totalPurchasePrice = useMemo(() => {
        return (
            purchase?.purchaseItems.reduce(
                (a, b) => a + b.unitPurchasePrice * (mapAmountFromApi(b.product?.measurementUnit, b.amount) || 0),
                0,
            ) || 0
        );
    }, [purchase]);

    const onComplete = async () => {
        try {
            await completePurchase({ id });
            navigate(`/stock/purchases/${id}/detail`);
        } catch (e) {
            throw e;
        }
    };

    const handleClose = () => {
        setOpen(false);
        setEditPurchaseItem(undefined);
    };

    return (
        <Card variant="outlined">
            {purchase && (
                <CardContent>
                    <Typography variant="subtitle1" pt={2} mb={1}>
                        {t('invoiceData')}
                    </Typography>
                    <Box sx={{ '.MuiTypography-root': { fontSize: '14px' } }}>
                        <LabelValue label={t('isGift')} value={purchase.gift ? t('yes') : t('no')} />
                        {purchase.invoiceNumber && (
                            <LabelValue label={t('invoiceNumber')} value={purchase.invoiceNumber} />
                        )}
                        <LabelValue label={t('date')} value={formatDateString(purchase.date.toString())} />
                        {purchase.supplier && <LabelValue label={t('supplier')} value={purchase.supplier?.name} />}
                    </Box>

                    <Divider sx={{ my: 2 }} />
                    <Typography variant="subtitle1">{t('products')}</Typography>
                    <PurchaseItemsTable
                        purchase={purchase}
                        setOpen={setOpen}
                        setEditPurchaseItem={setEditPurchaseItem}
                    />
                    <Typography variant="body1" sx={{ pt: 2, pr: 3, fontWeight: 'bold', textAlign: 'right' }}>
                        {t('total')} {formatPrice(totalPurchasePrice)}
                    </Typography>
                    <Button variant="outlined" sx={{ mt: 2 }} onClick={() => setOpen(true)}>
                        + {t('addProduct')}
                    </Button>
                    <SavePurchaseItem
                        purchase={purchase}
                        purchaseItem={editPurchaseItem}
                        open={open}
                        onClose={handleClose}
                    />
                </CardContent>
            )}

            <CardActions sx={{ backgroundColor: 'background.default' }}>
                <FormGrid containerProps={{ justifyContent: 'space-between' }}>
                    <Button
                        startIcon={<ChevronLeft />}
                        onClick={() => navigate(`/stock/purchases/${id}`)}
                        disabled={isPending}
                    >
                        {t('shared.previous')}
                    </Button>
                    <Stack spacing={2} direction="row">
                        <Button
                            color="secondary"
                            variant="outlined"
                            startIcon={<Sell />}
                            onClick={() => window.open(`/shop-api/purchases/${id}/prices`, '__blank')}
                        >
                            {t('downloadPriceLabels')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => setShowConfirm(true)}
                            disabled={isPending || !purchase?.purchaseItems?.length}
                        >
                            {t('completePurchase')}
                        </Button>
                    </Stack>
                </FormGrid>
            </CardActions>

            <CompletePurchaseActions
                open={showConfirm}
                onClose={() => setShowConfirm(false)}
                total={totalPurchasePrice}
                onConfirm={onComplete}
                isPending={isPending}
            />
        </Card>
    );
};
