import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { ControlledNumberInput } from '../../../shared';
import { useTranslation } from 'react-i18next';

export const RegisterSessionCashInputsCoins: FC = () => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" spacing={2}>
            <Stack spacing={2} width="100%">
                <ControlledNumberInput
                    name="amount1Cents"
                    label={t('amountCoins', { amount: '0,01' })}
                    slotProps={{ htmlInput: { readOnly: true } }}
                    decimalScale={0}
                />
                <ControlledNumberInput
                    name="amount2Cents"
                    label={t('amountCoins', { amount: '0,02' })}
                    slotProps={{ htmlInput: { readOnly: true } }}
                    decimalScale={0}
                />
                <ControlledNumberInput
                    name="amount5Cents"
                    label={t('amountCoins', { amount: '0,05' })}
                    slotProps={{ htmlInput: { readOnly: true } }}
                    decimalScale={0}
                />
                <ControlledNumberInput
                    name="amount10Cents"
                    label={t('amountCoins', { amount: '0,10' })}
                    slotProps={{ htmlInput: { readOnly: true } }}
                    decimalScale={0}
                />
            </Stack>

            <Stack spacing={2} width="100%">
                <ControlledNumberInput
                    name="amount20Cents"
                    label={t('amountCoins', { amount: '0,20' })}
                    slotProps={{ htmlInput: { readOnly: true } }}
                    decimalScale={0}
                />
                <ControlledNumberInput
                    name="amount50Cents"
                    label={t('amountCoins', { amount: '0,50' })}
                    slotProps={{ htmlInput: { readOnly: true } }}
                    decimalScale={0}
                />
                <ControlledNumberInput
                    name="amount1Euro"
                    label={t('amountCoins', { amount: 1 })}
                    slotProps={{ htmlInput: { readOnly: true } }}
                    decimalScale={0}
                />
                <ControlledNumberInput
                    name="amount2Euro"
                    label={t('amountCoins', { amount: 2 })}
                    slotProps={{ htmlInput: { readOnly: true } }}
                    decimalScale={0}
                />
            </Stack>
        </Stack>
    );
};
