import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    DialogTitleWithClose,
    formatPrice,
    ICash,
    IRegisterSession,
    useUpdateRegistrationSession,
} from '../../../shared';
import { calculateCashTotal, getDefaultCashInputs } from '../../utils';
import { useRegisterSession } from '../../validators';
import { RegisterSessionInputs } from '../register-session-inputs/register-session-inputs.component';

interface Props {
    registerSession: IRegisterSession;
    onClose: () => void;
    onSubmit: () => void;
}
export const StopRegisterSessionForm: FC<Props> = ({ registerSession, onClose, onSubmit }) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState<keyof ICash>('amount100Euro');
    const [total, setTotal] = useState(0);

    const { mutateAsync: updateRegisterSession, isPending } = useUpdateRegistrationSession();

    const form = useForm<ICash>({
        resolver: yupResolver(useRegisterSession(true)),
        mode: 'onSubmit',
        defaultValues: getDefaultCashInputs(),
    });

    useEffect(() => {
        form.reset({ ...registerSession?.cashAtStop });
    }, [form, registerSession]);

    const handleSubmit = useCallback(
        async (cash: ICash) => {
            await updateRegisterSession({
                id: registerSession.id,
                cashAtStop: cash,
                totalCashAtStop: calculateCashTotal(cash),
            });
            onSubmit();
        },
        [updateRegisterSession, registerSession, onSubmit],
    );

    const values = form.watch();
    useEffect(() => {
        setTotal(calculateCashTotal(values));
    }, [values]);

    return (
        <>
            <FormProvider {...form}>
                <form
                    onSubmit={form.handleSubmit(handleSubmit)}
                    onSelect={(event) => setSelected((event.target as HTMLInputElement).id as keyof ICash)}
                >
                    <DialogTitleWithClose onClose={onClose}>{t('countCashRegisterSession')}</DialogTitleWithClose>
                    <RegisterSessionInputs selected={selected} />
                    <DialogContent sx={{ backgroundColor: 'background.default', py: 0 }}>
                        <Box sx={{ my: 2 }}>
                            <Typography variant="subtitle1">
                                {t('totalInRegister')}: {formatPrice(total)}
                            </Typography>
                            <Typography variant="subtitle1">
                                {t('totalExpectedInRegister')}: {formatPrice(registerSession?.expectedTotalCashAtStop)}
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" fullWidth variant="contained" disabled={isPending}>
                            {t('stopRegisterSession')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </>
    );
};
