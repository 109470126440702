import { Button, DialogActions, DialogContent, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DialogTitleWithClose,
    formatPrice,
    formatToFixed,
    FormGrid,
    IRegisterSession,
    LabelValue,
    useStopRegistrationSession,
} from '../../../shared';

interface Props {
    registerSession: IRegisterSession;
    goBack: () => void;
    onClose: () => void;
}

export const StopRegisterSessionOverview: FC<Props> = ({ registerSession, goBack, onClose }) => {
    const { t } = useTranslation();
    const { mutateAsync: stopRegisterSession, isPending } = useStopRegistrationSession();

    const onCompleteRegisterSession = async () => {
        await stopRegisterSession(registerSession.id);
    };

    const registerDifference =
        (registerSession?.totalCashAtStop || 0) - (registerSession?.expectedTotalCashAtStop || 0);

    return (
        <>
            <DialogTitleWithClose onClose={onClose}>{t('registerResult')}</DialogTitleWithClose>
            <DialogContent>
                <FormGrid xs={12} sm={6}>
                    <Stack>
                        <LabelValue
                            label={t('totalCashIncome')}
                            value={formatPrice(registerSession?.totalCashIncome)}
                        />
                        <LabelValue
                            label={t('totalDigitalIncome')}
                            value={formatPrice(registerSession?.totalDigitalIncome)}
                        />
                        <LabelValue label={t('totalEarnings')} value={formatPrice(registerSession?.totalEarnings)} />
                    </Stack>
                    <Stack>
                        <LabelValue
                            label={t('totalCashAtStop')}
                            value={formatPrice(registerSession?.totalCashAtStop)}
                        />
                        <LabelValue
                            label={t('expectedTotalCashAtStop')}
                            value={formatPrice(registerSession?.expectedTotalCashAtStop)}
                        />
                        <LabelValue
                            label={t('differenceInExpectedCash')}
                            value={
                                registerDifference > 0
                                    ? `€ + ${formatToFixed(registerDifference)}`
                                    : formatPrice(registerDifference)
                            }
                        />
                    </Stack>
                </FormGrid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" fullWidth onClick={goBack} disabled={isPending}>
                    {t('previous')}
                </Button>
                <Button variant="contained" fullWidth onClick={onCompleteRegisterSession} disabled={isPending}>
                    {t('closeRegister')}
                </Button>
            </DialogActions>
        </>
    );
};
